import React from "react";
import { graphql } from "gatsby";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import Article from "../../components/Article";
import ArticleHeader from "../../components/ArticleHeader";
import MortgageCalculator from "../../components/MortgageCalculator";
import { urls, extendUrlWithSourceVersion } from "../../constants/urls";
import BreadcrumbList from "../../components/seo/BreadcrumbList";

const GrundbuchKosten = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader h1Title="Sind Fremdwährungskredite noch eine Option?" showCalc={false} />
            <Article>
                <p>
                    Vielleicht hast du schon einmal irgendwo von Fremdwährungskrediten gehört, weißt aber nicht mehr
                    genau worum es dabei ging. Vielleicht weißt du es auch und erinnerst dich noch an die vielen
                    Gruselgeschichte mit den aufgenommenen Krediten in Schweizer Franken. Kann so etwas wieder
                    passieren? Sind Fremdwährungskredite überhaupt etwas, das man sich überlegen sollte?
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Was ist ein Fremdwährungskredit eigentlich?</h2>
                <p>
                    Jeder Kredit, der in einer anderen Währung als Euro vergeben wird, wird in Österreich
                    Fremdwährungskredit genannt. Auch bei diesen{" "}
                    <Link to="/artikel/wohnkredit/" target="_blank" rel="noreferrer noopener">
                        Krediten
                    </Link>{" "}
                    werden{" "}
                    <Link to="/artikel/zinsen/" target="_blank" rel="noreferrer noopener">
                        Zinsen
                    </Link>{" "}
                    gezahlt, jedoch in der Währung, in welcher der Kredit aufgenommen wurde. Hast du also einen Kredit
                    in Schweizer Franken aufgenommen, müssen auch die Zinsen in Schweizer Franken bezahlt werden.
                </p>
                <p>
                    Wenn du einen Fremdwährungskredit zurückzahlst, hast du das Geld nicht in dieser Währung auf deinem
                    Konto. Das heißt, dass du sie zum aktuell gültigen Kurs erwerben musst. Das ist ein riskantes
                    Unterfangen, weil man nicht weiß, wie sich der Wechselkurs verändern wird. Wenn man Pech hat, ist
                    der{" "}
                    <Link to="/dienstleistungen/kreditrechner/" target="_blank" rel="noreferrer noopener">
                        Kredit
                    </Link>{" "}
                    am Ende teurer, als zur Zeit, in der du ihn aufgenommen hast.
                </p>
                <p>
                    So ist es vielen Österreicher*innen ergangen, die in den 90ern einen Kredit in Schweizer Franken
                    aufgenommen hatten. Die{" "}
                    <Link to="/artikel/kreditzinsen-entwicklung/" target="_blank" rel="noreferrer noopener">
                        Kreditzinsen
                    </Link>{" "}
                    waren früher im Vergleich zum Schilling und anderen europäischen Währungen sehr günstig. Damals
                    hatte man gedacht, dass die Wertentwicklung eine sehr kleine Schwankungsbreite hat, weil es sich um
                    ein Nachbarland handelte. Aber man kann so etwas nie genau vorhersagen. Im Endeffekt hat man sich
                    stark geirrt. Menschen, die einen Kredit in Schweizer Franken haben, müssen Kursverluste von bis zu
                    40 Prozent verzeichnen.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Warum nahmen Menschen Fremdwährungskredite auf</h2>
                <p>
                    Der Grund, einen Fremdwährungskredit aufzunehmen, liegt auf der Hand: man erhofft sich günstigere
                    Zinssätze, einen Gewinn. Im Grunde genommen handelt es sich also um Spekulation. Dass
                    Spekulationsgeschäfte immer riskant sind, wissen wir. Wenn sich der Wechselkurs in der fremden
                    Währung ändert, passiert das entweder zu deinem Vorteil, oder zu deinem Nachteil. Dann musst du eine
                    größere Geldsumme aufbringen, um den Kredit zurückzuzahlen. Man kann sich den Vergleich der
                    jeweiligen Referenzzinssätze, an denen sich die Währungen orientieren auf der Website der
                    Österreichischen Nationalbank ansehen.
                </p>
                <hr />

                <h2>Das Risiko eindämmen</h2>
                <p>
                    Um das Risiko für Privatpersonen zu verringern, wurde Kundinnen und Kunden ein Fremdwährungskredit
                    als sogenannter endfälliger Kredit ermöglicht. Das heißt, dass du während der Laufzeit nur die
                    Kreditzinsen bezahlen musst, die{" "}
                    <Link to="/artikel/tilgung/" target="_blank" rel="noreferrer noopener">
                        Tilgung
                    </Link>{" "}
                    allerdings erst am Ende der Laufzeit geschieht. Um die Summe dafür zusammenzusparen, wurden einem
                    während der Kreditaufnahme Tilgungsträger vermittelt, wie zum Beispiel Lebensversicherungen, Fonds
                    oder Aktien. Dadurch wurde es möglich, dass der Tilgungsträger doch einen höheren Ertrag abwirft,
                    als für den Fremdwährungskredit zurückzuzahlen gewesen wäre. Dann kann am Ende der Laufzeit der
                    Kredit getilgt und möglicherweise sogar Gewinn erwirtschaftet werden.
                </p>
                <p>
                    <strong>Aber:</strong> Durch die Finanzmarktkrise haben viele Tilgungsträger beträchtlich an Wert
                    verloren. Man muss sich als Kreditnehmer*in häufig davor fürchten, dass am Ende der Laufzeit die
                    Tilgungsträger nicht mehr ausreichend Wert besitzen, um den Kredit überhaupt zurückzuzahlen.
                </p>
                <hr />

                <h2>Fazit</h2>
                <p>
                    Jeder Fremdwährungskredit – ob nun in US-Dollar, YEN oder den berüchtigten Schweizer Franken – ist
                    und bleibt ein Spekulationsgeschäft. Man kann unmöglich vorhersehen, wie sich aktuelle Zinsvorteile
                    oder Wechselkurse in den nächsten Jahren entwickeln werden.
                </p>
                <p>
                    Das galt immer schon, muss aber in den Krisenzeiten, in denen wir nunmal leben, noch einmal
                    besonders betont werden. Auch die Erträge durch Tilgungsträger können nicht im Vorhinein bestimmt
                    werden. Seit der Finanzkrise 2008 wurden aufgrund der Gefahr für den Finanzmarkt, die von derart
                    riskanten Spekulationen ausgehen, Vergaben von Fremdwährungskrediten an Privatpersonen fast
                    ausnahmslos verboten. Seitdem konnten drei Viertel dieser riskanten Schulden abgebaut werden.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
            </Article>
            <MortgageCalculator noTopMargin defaultTerm={35} defaultMortgage={50000} />

            <BreadcrumbList page={"fremdwaehrungskredite"}></BreadcrumbList>
            <ArticleStructuredData
                page={"fremdwaehrungskredite"}
                heading={"Sind Fremdwährungskredite noch eine Option?"}
            />
        </Layout>
    );
};

export default GrundbuchKosten;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["header", "footer", "page.artikel.fremdwaehrungskredite", "mortgageCalc"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
